import React from 'react';
import styled from 'styled-components';

import breakPoints from './breakPoints';
import { GridItem, ProjectTypeGridItem } from '../components';
import livingWage from '../img/LW_logo_employer_rgb.svg';

const BlankGridItem = styled(GridItem)`
    font-size: 0.9rem;
    background-color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    letter-spacing: 1px;

    > div {
        &:first-child {
            flex-direction: column;
            display: flex;
            flex: 1;
            justify-content: flex-start;
        }

        &:last-child {
            flex-direction: column;
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }

        opacity: unset;
        text-align: ${(props) => props.direction};

        > p {
            margin: 0;
            letter-spacing: 0px;
            font-weight: 400;
        }
    }

    @media (max-width: ${breakPoints.portrait}px) {
        font-size: 16px;
    }

    @media (min-width: ${breakPoints.portrait}px) and (max-width: ${breakPoints.tablet - 1}px) {
    }

    @media (min-width: ${breakPoints.tablet}px) and (max-width: ${breakPoints.widescreen - 1}px) {
    }

    @media (min-width: ${breakPoints.widescreen}px) and (max-width: ${breakPoints.fullhd - 1}px) {
    }

    @media (min-width: ${breakPoints.fullhd}px) {
    }
`;

const aboutUsSquare = [
    <>
        <div>
            <img src={livingWage} style={{ maxWidth: '100px', margin: '0 10px', position: 'absolute' }} />
            <p>30 Bell Street</p>
            <p>Glasgow</p>
            <p>G1 1LG</p>
            <p>T: 0141 553 1999</p>
            <p>E: admin@grantmurray.co.uk</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <p>
                Grant Murray welcome talented individuals to our team. Applicants should email their portfolio & CV to the
                address above.
            </p>
        </div>
    </>,
];

const ourStorySquare = [
    <>
        <div style={{ textAlign: 'justify' }}>
            <p>
                Formed in 1985, Grant Murray Architects is an established architectural practice in the heart of Glasgow
                offering award winning designs, an honest, friendly service & efficient delivery.
            </p>
        </div>
        <div style={{ textAlign: 'justify' }}>
            <p>
                Having completed countless projects across a variety of sectors, the team at GMA take pride in our focused,
                hands on approach no matter the project scale, cost or client.
            </p>
        </div>
    </>,
];

const OurStudioSquare = styled(ProjectTypeGridItem)`
    > div {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        > h4 {
            width: 40%;
            font-weight: bold;
            text-transform: uppercase;
            border: none;
            position: relative;
            text-align: right;
            margin: 5px;
        }
    }
`;

export const projects = [
    <GridItem
        image="/our-studio/20210902_164334_zvvugx.jpg"
        style={{
            gridColumn: '1 / span 5',
            clipPath: 'polygon(0 0, 80% 0, 100% 100%, 20% 100%)',
            backgroundPosition: 'center',
        }}
    />,
    <GridItem
        image="/our-studio/CREDITATIONS_SQUARE_elzact.jpg"
        style={{
            gridColumn: 'span 2',
            backgroundPosition: 'center',
            backgroundSize: '75%',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
        }}
    />,
    <GridItem hide />,
    <BlankGridItem style={{ gridColumn: 'span 2' }} direction="right">
        {aboutUsSquare}
    </BlankGridItem>,
    <OurStudioSquare color="#a9d76e">
        <div>
            <h4>Our Studio</h4>
        </div>
    </OurStudioSquare>,
    <BlankGridItem style={{ gridColumn: 'span 2' }} direction="left">
        {ourStorySquare}
    </BlankGridItem>,
    <GridItem hide />,
    <GridItem hide />,
    <GridItem image="/our-studio/ISO_SQUARE_fulawk.jpg" />,
    <GridItem image="/our-studio/MAP_duvij1.jpg" style={{ gridColumn: 'span 2' }} />,
    <GridItem
        image="/our-studio/OFFICE_IMAGE_2_p24jsp.jpg"
        style={{ gridColumn: 'span 3', clipPath: 'polygon(0 0, 66% 0, 100% 100%, 0 100%)' }}
    />,
];
export const tabletProjects = [
    <GridItem
        image="/our-studio/20210902_164334_zvvugx.jpg"
        style={{ gridColumn: '1 / span 3', backgroundPosition: 'center' }}
    />,
    <GridItem
        image="/our-studio/CREDITATIONS_SQUARE_elzact.jpg"
        style={{ gridColumn: 'span 2', backgroundPosition: 'center  ' }}
    />,
    <BlankGridItem style={{ gridColumn: 'span 2' }} direction="right">
        {aboutUsSquare}
    </BlankGridItem>,
    <OurStudioSquare color="#a9d76e">
        <div>
            <h4>Our Studio</h4>
        </div>
    </OurStudioSquare>,
    <BlankGridItem style={{ gridColumn: 'span 2' }} direction="left">
        {ourStorySquare}
    </BlankGridItem>,
    <GridItem image="/our-studio/ISO_SQUARE_fulawk.jpg" />,
    <GridItem image="/our-studio/MAP_duvij1.jpg" style={{ gridColumn: 'span 2' }} />,
    <GridItem
        image="/our-studio/OFFICE_IMAGE_2_p24jsp.jpg"
        style={{ gridColumn: 'span 2', backgroundPosition: 'center' }}
    />,
];

export const portraitProjects = [
    <GridItem
        image="/our-studio/20210902_164334_zvvugx.jpg"
        style={{ gridColumn: '1 / span 3', backgroundPosition: 'center' }}
    />,
    <BlankGridItem direction="right">{aboutUsSquare}</BlankGridItem>,
    <OurStudioSquare color="#a9d76e">
        <div>
            <h4>Our Studio</h4>
        </div>
    </OurStudioSquare>,
    <BlankGridItem direction="left">{ourStorySquare}</BlankGridItem>,
    <GridItem image="/our-studio/MAP_duvij1.jpg" style={{ gridColumn: 'span 2' }} />,
    <GridItem image="/our-studio/CREDITATIONS_SQUARE_elzact.jpg" />,
    <GridItem image="/our-studio/ISO_SQUARE_fulawk.jpg" />,
    <GridItem image="/our-studio/OFFICE_IMAGE_2_p24jsp.jpg" style={{ gridColumn: 'span 2' }} />,
];

const MobileOurStory = styled(BlankGridItem)`
    &&& div {
        justify-content: center;
    }
`;

export const mobileProjects = [
    <GridItem image="/our-studio/20210902_164334_zvvugx.jpg" style={{ backgroundPosition: 'center' }} />,
    <MobileOurStory direction="left">{ourStorySquare}</MobileOurStory>,
    <OurStudioSquare color="#a9d76e">
        <div>
            <h4>Our Studio</h4>
        </div>
    </OurStudioSquare>,
    <BlankGridItem direction="right">{aboutUsSquare}</BlankGridItem>,
    <GridItem image="/our-studio/MAP_duvij1.jpg" />,
    <GridItem
        image="/our-studio/CREDITATIONS_COMBINED_SQUARE_lrxhxk.jpg"
        style={{
            backgroundSize: '80%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
        }}
    />,
    <GridItem image="/our-studio/OFFICE_IMAGE_2_p24jsp.jpg" />,
];
