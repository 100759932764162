import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Media } from '../components/Media';
import logo from '../img/grant-murray-logo-wide.svg';
import { Layout, ProjectTypeGrid, GridContainer } from '../components';
import Footer from '../components/Footer';
import { projects, tabletProjects, portraitProjects, mobileProjects } from '../helpers/ourStudioItems';

const LogoGridItem = ({ style }) => (
    <Link to="/" style={{ display: 'flex', ...style }}>
        <img src={logo} alt="Grant Murray" />
    </Link>
);

LogoGridItem.propTypes = {
    style: PropTypes.object.isRequired,
};

export const StudioPageTemplate = ({ projectTypes }) => (
    <Layout title="Our Studio" description="Welcome to our studio">
        <GridContainer>
            <Media at="mobile">
                <ProjectTypeGrid viewHeight="33.333333333vh" projects={mobileProjects}>
                    <LogoGridItem />
                    {mobileProjects}
                </ProjectTypeGrid>
            </Media>
            <Media at="portrait">
                <ProjectTypeGrid projects={portraitProjects}>{portraitProjects}</ProjectTypeGrid>
            </Media>
            <Media at="tablet">
                <ProjectTypeGrid projects={tabletProjects}>{tabletProjects}</ProjectTypeGrid>
            </Media>
            <Media at="widescreen">
                <ProjectTypeGrid projects={projects}>{projects}</ProjectTypeGrid>
            </Media>
            <Media greaterThanOrEqual="fullhd">
                <ProjectTypeGrid projects={projects}>{projects}</ProjectTypeGrid>
            </Media>
            <Footer />
        </GridContainer>
    </Layout>
);

StudioPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    projectTypes: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string,
            title: PropTypes.string,
        })
    ),
};

const StudioPage = () => <StudioPageTemplate />;

export default StudioPage;
